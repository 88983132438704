import { render, staticRenderFns } from "./searchBox.vue?vue&type=template&id=0eaaeebc&scoped=true"
import script from "./searchBox.vue?vue&type=script&lang=js"
export * from "./searchBox.vue?vue&type=script&lang=js"
import style0 from "./searchBox.vue?vue&type=style&index=0&id=0eaaeebc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eaaeebc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSearchBox: require('/home/erge/mnt/code/company/nuxt-shop/components/InputSearchBox.vue').default,ForUserButton: require('/home/erge/mnt/code/company/nuxt-shop/components/ForUserButton.vue').default})
