const middleware = {}

middleware['lang'] = require('../middleware/lang.js')
middleware['lang'] = middleware['lang'].default || middleware['lang']

middleware['loading'] = require('../middleware/loading.js')
middleware['loading'] = middleware['loading'].default || middleware['loading']

middleware['token'] = require('../middleware/token.js')
middleware['token'] = middleware['token'].default || middleware['token']

export default middleware
