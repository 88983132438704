export const BackToTop = () => import('../../components/BackToTop.vue' /* webpackChunkName: "components/back-to-top" */).then(c => wrapFunctional(c.default || c))
export const ForUserButton = () => import('../../components/ForUserButton.vue' /* webpackChunkName: "components/for-user-button" */).then(c => wrapFunctional(c.default || c))
export const InputSearchBox = () => import('../../components/InputSearchBox.vue' /* webpackChunkName: "components/input-search-box" */).then(c => wrapFunctional(c.default || c))
export const PreviewImg = () => import('../../components/PreviewImg.vue' /* webpackChunkName: "components/preview-img" */).then(c => wrapFunctional(c.default || c))
export const XimuFooter = () => import('../../components/XimuFooter.vue' /* webpackChunkName: "components/ximu-footer" */).then(c => wrapFunctional(c.default || c))
export const XimuHeader = () => import('../../components/XimuHeader.vue' /* webpackChunkName: "components/ximu-header" */).then(c => wrapFunctional(c.default || c))
export const XmAreaSelectAll = () => import('../../components/XmAreaSelectAll.vue' /* webpackChunkName: "components/xm-area-select-all" */).then(c => wrapFunctional(c.default || c))
export const XmPageTitle = () => import('../../components/XmPageTitle.vue' /* webpackChunkName: "components/xm-page-title" */).then(c => wrapFunctional(c.default || c))
export const Indexbottom = () => import('../../components/indexbottom.vue' /* webpackChunkName: "components/indexbottom" */).then(c => wrapFunctional(c.default || c))
export const PayHeader = () => import('../../components/payHeader.vue' /* webpackChunkName: "components/pay-header" */).then(c => wrapFunctional(c.default || c))
export const Searchaddress = () => import('../../components/searchaddress.vue' /* webpackChunkName: "components/searchaddress" */).then(c => wrapFunctional(c.default || c))
export const XimuDialog = () => import('../../components/XimuComponents/XimuDialog.vue' /* webpackChunkName: "components/ximu-dialog" */).then(c => wrapFunctional(c.default || c))
export const XimuProtocolDialog = () => import('../../components/XimuComponents/XimuProtocolDialog.vue' /* webpackChunkName: "components/ximu-protocol-dialog" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmCardTabs = () => import('../../components/XimuComponents/XmCardTabs.vue' /* webpackChunkName: "components/ximu-components-xm-card-tabs" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmCarousel = () => import('../../components/XimuComponents/XmCarousel.vue' /* webpackChunkName: "components/ximu-components-xm-carousel" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmContact = () => import('../../components/XimuComponents/XmContact.vue' /* webpackChunkName: "components/ximu-components-xm-contact" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmGoodsMark = () => import('../../components/XimuComponents/XmGoodsMark.vue' /* webpackChunkName: "components/ximu-components-xm-goods-mark" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmIdentityCardVerify = () => import('../../components/XimuComponents/XmIdentityCardVerify.vue' /* webpackChunkName: "components/ximu-components-xm-identity-card-verify" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmInCountrySelect = () => import('../../components/XimuComponents/XmInCountrySelect.vue' /* webpackChunkName: "components/ximu-components-xm-in-country-select" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmMoreOperations = () => import('../../components/XimuComponents/XmMoreOperations.vue' /* webpackChunkName: "components/ximu-components-xm-more-operations" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmPreviewImage = () => import('../../components/XimuComponents/XmPreviewImage.vue' /* webpackChunkName: "components/ximu-components-xm-preview-image" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmPreviewImageTable = () => import('../../components/XimuComponents/XmPreviewImageTable.vue' /* webpackChunkName: "components/ximu-components-xm-preview-image-table" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmPreviewdoc = () => import('../../components/XimuComponents/XmPreviewdoc.vue' /* webpackChunkName: "components/ximu-components-xm-previewdoc" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmPreviewimg = () => import('../../components/XimuComponents/XmPreviewimg.vue' /* webpackChunkName: "components/ximu-components-xm-previewimg" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmRadioCascade = () => import('../../components/XimuComponents/XmRadioCascade.vue' /* webpackChunkName: "components/ximu-components-xm-radio-cascade" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmReconfirm = () => import('../../components/XimuComponents/XmReconfirm.vue' /* webpackChunkName: "components/ximu-components-xm-reconfirm" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmShowFileBox = () => import('../../components/XimuComponents/XmShowFileBox.vue' /* webpackChunkName: "components/ximu-components-xm-show-file-box" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmTeleport = () => import('../../components/XimuComponents/XmTeleport.vue' /* webpackChunkName: "components/ximu-components-xm-teleport" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmTip = () => import('../../components/XimuComponents/XmTip.vue' /* webpackChunkName: "components/ximu-components-xm-tip" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmTopNav = () => import('../../components/XimuComponents/XmTopNav.vue' /* webpackChunkName: "components/ximu-components-xm-top-nav" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmTopNavBackstage = () => import('../../components/XimuComponents/XmTopNavBackstage.vue' /* webpackChunkName: "components/ximu-components-xm-top-nav-backstage" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmUpload = () => import('../../components/XimuComponents/XmUpload.vue' /* webpackChunkName: "components/ximu-components-xm-upload" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmUploader = () => import('../../components/XimuComponents/XmUploader.vue' /* webpackChunkName: "components/ximu-components-xm-uploader" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmVideo = () => import('../../components/XimuComponents/XmVideo.vue' /* webpackChunkName: "components/ximu-components-xm-video" */).then(c => wrapFunctional(c.default || c))
export const CommeNewDemand = () => import('../../components/comme/NewDemand.vue' /* webpackChunkName: "components/comme-new-demand" */).then(c => wrapFunctional(c.default || c))
export const CommeRemoveDialog = () => import('../../components/comme/RemoveDialog.vue' /* webpackChunkName: "components/comme-remove-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommeUnDiabg = () => import('../../components/comme/UnDiabg.vue' /* webpackChunkName: "components/comme-un-diabg" */).then(c => wrapFunctional(c.default || c))
export const CommeAccountManager = () => import('../../components/comme/accountManager.vue' /* webpackChunkName: "components/comme-account-manager" */).then(c => wrapFunctional(c.default || c))
export const CommeAreaCheck = () => import('../../components/comme/areaCheck.vue' /* webpackChunkName: "components/comme-area-check" */).then(c => wrapFunctional(c.default || c))
export const CommeContactBackend = () => import('../../components/comme/contactBackend.vue' /* webpackChunkName: "components/comme-contact-backend" */).then(c => wrapFunctional(c.default || c))
export const CommeDeleteConfig = () => import('../../components/comme/deleteConfig.vue' /* webpackChunkName: "components/comme-delete-config" */).then(c => wrapFunctional(c.default || c))
export const CommeLeftmenu = () => import('../../components/comme/leftmenu.vue' /* webpackChunkName: "components/comme-leftmenu" */).then(c => wrapFunctional(c.default || c))
export const CommeMessageBox = () => import('../../components/comme/messageBox.vue' /* webpackChunkName: "components/comme-message-box" */).then(c => wrapFunctional(c.default || c))
export const CommeSearchBox = () => import('../../components/comme/searchBox.vue' /* webpackChunkName: "components/comme-search-box" */).then(c => wrapFunctional(c.default || c))
export const XmNewAreaSelect = () => import('../../components/xm-new-area-select/XmNewAreaSelect.vue' /* webpackChunkName: "components/xm-new-area-select" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsPagTools = () => import('../../components/XimuComponents/pag-tools/PagTools.vue' /* webpackChunkName: "components/ximu-components-pag-tools" */).then(c => wrapFunctional(c.default || c))
export const XimuClose = () => import('../../components/XimuComponents/ximu-close/XimuClose.vue' /* webpackChunkName: "components/ximu-close" */).then(c => wrapFunctional(c.default || c))
export const XimuNoTitleConfig = () => import('../../components/XimuComponents/ximu-no-title-config/XimuNoTitleConfig.vue' /* webpackChunkName: "components/ximu-no-title-config" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmCategory = () => import('../../components/XimuComponents/xm-category/XmCategory.vue' /* webpackChunkName: "components/ximu-components-xm-category" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmDatePicker = () => import('../../components/XimuComponents/xm-datePicker/index.vue' /* webpackChunkName: "components/ximu-components-xm-date-picker" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmEmpty = () => import('../../components/XimuComponents/xm-empty/XmEmpty.vue' /* webpackChunkName: "components/ximu-components-xm-empty" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmExportButton = () => import('../../components/XimuComponents/xm-export/XmExportButton.vue' /* webpackChunkName: "components/ximu-components-xm-export-button" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmExportHistory = () => import('../../components/XimuComponents/xm-export/XmExportHistory.vue' /* webpackChunkName: "components/ximu-components-xm-export-history" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmGoodsBox = () => import('../../components/XimuComponents/xm-goods-box/XmGoodsBox.vue' /* webpackChunkName: "components/ximu-components-xm-goods-box" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmHoverPreviewImage = () => import('../../components/XimuComponents/xm-hover-preview-image/XmHoverPreviewImage.vue' /* webpackChunkName: "components/ximu-components-xm-hover-preview-image" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmInput = () => import('../../components/XimuComponents/xm-input/XmInput.vue' /* webpackChunkName: "components/ximu-components-xm-input" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmPaged = () => import('../../components/XimuComponents/xm-paged/XmPaged.vue' /* webpackChunkName: "components/ximu-components-xm-paged" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmPreviewMedia = () => import('../../components/XimuComponents/xm-preview-media/XmPreviewMedia.vue' /* webpackChunkName: "components/ximu-components-xm-preview-media" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmSkuCompareItem = () => import('../../components/XimuComponents/xm-sku-compare-column/SkuCompareItem.vue' /* webpackChunkName: "components/ximu-components-xm-sku-compare-item" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmSkuCompareColumn = () => import('../../components/XimuComponents/xm-sku-compare-column/index.vue' /* webpackChunkName: "components/ximu-components-xm-sku-compare-column" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmSelect = () => import('../../components/XimuComponents/xm-select/XmSelect.vue' /* webpackChunkName: "components/ximu-components-xm-select" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmStepIcon = () => import('../../components/XimuComponents/xm-steps/XmStepIcon.vue' /* webpackChunkName: "components/ximu-components-xm-step-icon" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmSteps = () => import('../../components/XimuComponents/xm-steps/index.vue' /* webpackChunkName: "components/ximu-components-xm-steps" */).then(c => wrapFunctional(c.default || c))
export const XimuComponentsXmUploadFileName = () => import('../../components/XimuComponents/xm-upload-file-name/XmUploadFileName.vue' /* webpackChunkName: "components/ximu-components-xm-upload-file-name" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
