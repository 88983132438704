
import FormTitle from './component/FormTitleItem.vue';
import UpInput from './component/UpInputItem.vue';
import WideButton from './component/WideButtonItem.vue';
import ErrorTip from './component/ErrorTipItem.vue';
import ShopAccountClose from './component/ShopAccountClose.vue';
import ShopLimitation from './component/ShopLimitation.vue';
import SupplierAccountClose from './component/SupplierAccountClose.vue';
import SupplierLimitation from './component/SupplierLimitation.vue';
import { encryptor } from '@/utils/XmAES128Encryptor.js';
import { startDetector } from '@/utils/auto_logout';
import {
  getLastVisitedRoute,
  clearLastVisitedRoute,
} from '@/utils/history_recorder';
import { validatePhoneNumberReg } from '@/utils/validator.js';
import { mapMutations } from 'vuex';
// 跨域登录
import {
  crossDomainLogin,
  removeDom,
} from '@/utils/cross-domain-login/cross_domain_login.js';
// 工具函数
import tool from '@/utils/tool-function/tool.js';

// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  components: {
    FormTitle,
    UpInput,
    WideButton,
    ErrorTip,
    ShopAccountClose,
    ShopLimitation,
    SupplierAccountClose,
    SupplierLimitation,
  },
  data() {
    return {
      loginWay: 'pwd', // 登录方式 pwd密码登录 code验证码登录
      showErrorTip: false,
      errorTipContent: '',
      // 密码登录
      username: '',
      password: '',
      // 验证码登录
      phone: '',
      code: '',
      shopShow: false,
      shopLimt: false, // 分销商账号限制弹框
      supplierShow: false, // 供应商冻结账号
      supplierLimt: false,
      limtObj: {}, // 账号限制，账号冻结数据
      limitTip: '',

      loading: false, // 是否加载
    };
  },
  computed: {
    supplierRedirectPage() {
      let host = process.env.NUXT_ENV.VUE_APP_SUPPLIER_HOST;
      return `${host}/redirect`;
    },
  },
  methods: {
    ...mapMutations('login', [
      'setToken',
      'setUserInfo',
      'setLoginAllFormByKey',
    ]),
    toForgetPassword() {
      this.$router.push('/login/forgetPassword');
    },
    toRegister() {
      this.$router.push('/register');
    },
    // 修改手机号
    toModifyPhone() {
      this.$router.push('/login/modifyPhone');
    },
    toSupplier(data) {
      // let redirectIframe = document.getElementById('to-supplier-redirect');
      let targetUrl = this.supplierRedirectPage;
      const redirectIframe = document.createElement('iframe');
      redirectIframe.style = 'display: none;';
      redirectIframe.src = targetUrl;
      let containerDom = document.getElementById('xm-login-form');
      containerDom.appendChild(redirectIframe);
      redirectIframe.onload = function (event) {
        redirectIframe.contentWindow.postMessage(data, targetUrl);
        window.open(targetUrl, '_self');
      };
    },
    //跳转到首页
    toHomepage(type, data) {
      if (!type) {
        this.$message.error('服务器错误，请稍后');
        return;
      }
      if (type == 'distributor') {
        // 分销商
        crossDomainLogin('xm-login-form');
        // 状态是6代表审核注销通过
        if (data.user.status == '6') {
          this.$router.push('/account/logoutaccount');
        } else {
          let url = getLastVisitedRoute();
          clearLastVisitedRoute();
          let routeData = this.$router.resolve(url);
          // this.distributorJump(routeData.href);
          this.$router.push(url);
        }
      } else if (type == 'supplier') {
        // 供应商
        if (this.getBrowserType() === 'Safari') {
          // #memo 特殊处理非 chromium 内核的浏览器跳转，非 chromium 内核
          // 浏览器使用 iframe + postMessage 跨域传参会被同源策略拦截
          window.open(
            process.env.NUXT_ENV.VUE_APP_SUPPLIER_HOST + '/templogin',
            '_self'
          );
          return;
        }
        this.toSupplier(data);
      }
    },

    /**
     *分销商登录跳转配置（#TODO 这么搞的原因是为了搞临时单点登录）
     * @param {String} returnUrl 返回链接
     */
    async distributorJump(returnUrl) {
      try {
        let goBackUrl = location.origin + returnUrl;
        let temporaryToken = '';
        let goUrl =
          process.env.NUXT_ENV.VUE_APP_SHOP_EN_HOST +
          '/waitpage' +
          `?redirectURL=${goBackUrl}`;
        let res = await this.$setTemporaryToken();
        if (res?.errcode == 0) {
          temporaryToken = encodeURIComponent(res?.data?.sign || '');
          goUrl =
            process.env.NUXT_ENV.VUE_APP_SHOP_EN_HOST +
            '/waitpage' +
            `?redirectURL=${goBackUrl}` +
            '&userId=' +
            temporaryToken;
          window.open(goUrl, '_self');
        } else {
          window.open(goUrl, '_self');
        }
      } catch (error) {
        console.error(error);
        window.open(goUrl, '_self');
      }
    },

    async login() {
      if (this.loading) return;
      if (this.loginWay == 'pwd') {
        // 检查用户名是否填写
        if (!this.username.trim()) {
          this.handleShowErrorTip('请输入用户名');
          return;
        }
        // 检查密码是否填写
        if (!this.password) {
          this.handleShowErrorTip('请输入密码');
          return;
        }
      } else {
        let phoneReg = new RegExp(validatePhoneNumberReg);
        // 检查手机号是否填写
        if (!this.phone.trim() && !phoneReg.test(this.phone.trim())) {
          this.handleShowErrorTip('手机号码格式不正确，请重新输入');
          return;
        }
        // 检查验证码是否填写
        if (!this.code) {
          this.handleShowErrorTip('请输入验证码');
          return;
        }
      }

      this.loading = true;
      try {
        this.handleHideErrorTip();
        let res;
        if (this.loginWay == 'pwd') {
          const postPwdData = {
            account: this.username.trim(),
            password: encryptor.encrypt(this.password),
            MUTE_WARNING: 1,
          };
          res = await this.$loginAll(postPwdData);
        } else {
          const postCodeData = {
            account: this.phone?.trim(),
            code: this.code?.trim(),
            MUTE_WARNING: 1,
          };
          res = await this.$smsCodeLogin(postCodeData);
        }
        if (res.errcode == 0) {
          // 将用户信息保存在localStorage
          const type = res.data.type || res.data.user.type;
          let uid, im_token;
          if (type == 'distributor') {
            uid = res.data.user.user_id;
            im_token = res.data.user.im_token;
          } else {
            uid = res.data.uid;
            im_token = res.data.im_token;
          }
          // 保存分销商的用户数据到localStorage
          if (type == 'distributor') {
            // TODO 解决用户信息混乱临时方案，登录分销商成功之后，先清除缓存中所有的分销商信息，让登录和和获取登录信息先睡一会
            tool.removeLoginInfo();
            setTimeout(() => {
              this.handleSaveLocalStorageShop(res.data);
              this.recordLastLogin();
            }, 200);
          }
          // 设置账号是否被限制标识
          localStorage.setItem('is_limited_ximu_account', 0);
          this.setUserInfo({
            key: 'is_limited_ximu_account',
            value: 0,
          });
          this.loading = false;
          if (type == 'distributor') {
            this.$message({
              message: '登录成功!',
              type: 'success',
              duration: 1000,
            });
          }

          setTimeout(() => {
            this.toHomepage(type, res.data);
            this.resetData();
          }, 500);
        } else if (res.errcode == 110) {
          this.handleShowErrorTip('用户名或密码错误。请重新输入');
        } else if (res.errcode == -106 && res.data.type == 'supplier') {
          // 供应商关闭账号
          this.supplierShow = true;
          this.limitTip = res.msg;
          this.limtObj = res.data;
        } else if (res.errcode == -107 && res.data.type == 'supplier') {
          // 供应商冻结账号
          this.supplierLimt = true;
          this.limtObj = res.data;
          this.limitTip = res.msg;
        } else if (res.errcode == -106 && res.data.type == 'distributor') {
          // 分销商关闭账号
          this.shopShow = true;
          this.limitTip = res.msg;
          this.limtObj = res.data;
          let im_token = res.data.token;
          localStorage.setItem(
            CUR_TOKEN_NAME,
            JSON.parse(JSON.stringify(im_token))
          );
          this.setToken(JSON.parse(JSON.stringify(res.data.token)));
          localStorage.setItem('userdetail', JSON.stringify(res.data.user));
          this.setUserInfo({
            key: 'userdetail',
            value: JSON.stringify(res.data.user),
          });
          // 增加账号被限制的标识
          localStorage.setItem('is_limited_ximu_account', 1);
          this.setUserInfo({
            key: 'is_limited_ximu_account',
            value: 1,
          });
        } else if (res.errcode == -107 && res.data.type == 'distributor') {
          // 分销商冻结账号
          this.shopLimt = true;
          this.limitTip = res.msg;
          let im_token = res.data.token;
          localStorage.setItem(
            CUR_TOKEN_NAME,
            JSON.parse(JSON.stringify(im_token))
          );
          this.setToken(JSON.parse(JSON.stringify(res.data.token)));
          localStorage.setItem('userdetail', JSON.stringify(res.data.user));
          this.setUserInfo({
            key: 'userdetail',
            value: JSON.stringify(res.data.user),
          });
          // 增加账号被限制的标识
          localStorage.setItem('is_limited_ximu_account', 1);
          this.setUserInfo({
            key: 'is_limited_ximu_account',
            value: 1,
          });
        } else {
          this.handleShowErrorTip(res.msg || '网络异常，请刷新页面重试');
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    // 分销商记录最近一次输入密码登录的时间（为了做超时登录）
    recordLastLogin() {
      if (!this.$dynamicDetector) {
        this.$dynamicDetector = startDetector();
      }
      let recorder = this.$dynamicDetector.getRecorder();
      recorder();
    },
    resetData() {
      this.userName = '';
      this.password = '';
    },
    // 保存登录信息数据
    // 分销商
    handleSaveLocalStorageShop(data) {
      localStorage.setItem(
        CUR_TOKEN_NAME,
        JSON.parse(JSON.stringify(data.token))
      );
      localStorage.setItem('userdetail', JSON.stringify(data.user));
      localStorage.setItem('statusShow', data.user.status);
      this.setUserInfo({ key: 'userdetail', value: JSON.stringify(data.user) });
      this.setUserInfo({ key: 'statusShow', value: data.user.status });
      this.setToken(JSON.parse(JSON.stringify(data.token)));
    },
    // 展示错误提示
    handleShowErrorTip(content) {
      if (!content) return;
      this.showErrorTip = true;
      this.errorTipContent = content;
    },
    // 隐藏错误提示
    handleHideErrorTip() {
      this.showErrorTip = false;
    },
    handleUpdateStore(val, key) {
      this.setLoginAllFormByKey({ key: key, value: val });
    },
    // 关闭分销商账号关闭
    closeShop() {
      this.shopShow = false;
    },
    // 分销商限制账号登录
    closeShopLimt() {
      this.shopLimt = false;
    },
    // 供应商账号关闭弹框
    closeSupplier() {
      this.supplierShow = false;
    },
    // 供应商账号限制
    closeSupplierLimt() {
      this.supplierLimt = false;
    },
  },
  mounted() {
    this.username = this.$store.state.login.loginAllForm.phone;
    this.password = this.$store.state.login.loginAllForm.password;
    this.phone = this.$store.state.login.loginAllForm.codePhone; // 短信登录手机号
  },
  destroyed() {
    removeDom('xm-login-form');
  },
};
